$color__yellow: #FFC426;
$color__dark: #18265d;
$color__white: #fff;
$color__green: #17747c;
$color__red: #d93838;

// breakpoints
$breakpoint__md: 768px;
$breakpoint__lg: 992px;
$breakpoint__xl: 1199px;

$font__primary: mr-eaves-modern, sans-serif;
$font__secondary: filson-pro, sans-serif;