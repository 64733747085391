.header {

    &.featured-header {
        background-color: $color__dark;
        color: $color__white;
        padding: 100px 20px;

        position: relative;

        // margin-bottom: 110px;

        overflow: hidden;

        min-height: calc(100vh - 62px);

        display: flex;
        align-items: center;

        h1 {
            z-index: 1;

            .highlight {
                border-bottom: solid 12px;
                line-height: 0.8em!important;
                border-color: #ffc425;
                height: auto;
                display: inline-block;
            }
        }

        .landscape-visual-interest {
            width: 100%;
            background-size: auto calc(100% + 30px)!important;
            height: 340px;
            background-repeat: no-repeat !important;
            position: absolute;
            background-position: -32px!important;
            left: 0;
            bottom: -132px;
            z-index: 0;

            svg {
                height: auto;
                width: calc(100% + 80px);
                margin: 0 -40px;
                height: 340px;
            }

            .visual-interest-sun {
                position: absolute;
                right: 80px;
                bottom: 170px;
                width: 200px;
                height: 200px;
                background: $color__yellow;
                border-radius: 50%;
                z-index: -1;
            }

            .visual-interest-red {
                position: absolute;
                right: -80px;
                bottom: 150%;
                width: 200px;
                height: 200px;
                background: $color__red;
                border-radius: 50%;
                z-index: -1;

                &:before {
                    content: '';
                    position: absolute;

                    width: 150px;
                    height: 150px;
                    background: #18265d;
                    border-radius: 50%;
                    z-index: -1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

@media screen and (min-width: $breakpoint__lg) {
    .header {
        &.featured-header {
            h1 {
                max-width: 50%;
            }
        }
    }
    
}