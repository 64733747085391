.btn {
    font-weight: 900;
    font-size: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px -5px;

    cursor: pointer;

    font-family: $font__secondary;

    transition: all 0.2s ease-in-out;

    &.btn-primary {
        background-color: $color__yellow;

        &:hover {
            background-color: darken($color__yellow, 5);
        }
    }

    &:hover {
        box-shadow: none;
    }
}