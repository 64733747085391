// @import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import "the-new-css-reset/css/reset.css"; // reset

// base
@import 'base/variables';

// atoms
@import 'atoms/headings';
@import 'atoms/copy';
@import 'atoms/fields';
@import 'atoms/buttons';

// organisms
@import 'organisms/nav';
@import 'organisms/header';
@import 'organisms/blurb';
@import 'organisms/footer';

.features-container {
    overflow-y: hidden;
}

.feature {
    padding: 20px 10px;
}

.form-cta-container {
    padding-bottom: 100px;
    text-align: center;
}