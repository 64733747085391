h1 {
    font-size: 60pt;
    font-family: $font__secondary;
    font-weight: 900;
    line-height: 1em;
}

h2 {
    font-size: 35pt;
    font-family: $font__primary;
    font-weight: 900;
    line-height: 1em;
}

@media screen and (min-width: $breakpoint__md) {
    h1 {
        font-size: 72pt;
    }

    h2 {
        font-size: 35pt;
    }
}