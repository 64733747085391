footer {
    display: flex;
    justify-content: flex-end;

    padding: 5px;
    // background-color: rgba(0,0,0,0.05);

    .copyright {
        align-self: flex-end;
        text-align: right;

        p {
            text-transform: lowercase;
            font-size: 15px;
        }
    }
}